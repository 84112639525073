import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import( '../views/Product.vue'),
   
  },
  {
    path: '/product/product-one',
    name: 'ProductOne',
    component: () => import( '../views/ProductOne.vue')
  },
  {
    path: '/product/product-two',
    name: 'ProductTwo',
    component: () => import( '../views/ProductTwo.vue')
  },
  {
    path: '/product/product-three',
    name: 'ProductThree',
    component: () => import( '../views/ProductThree.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import( '../views/News.vue')
  },
  {
    path: '/jjfa',
    name: 'Jjfa',
    component: () => import( '../views/Jjfa.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})
//解决点击重复的path报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  console.log("aa",to);
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
