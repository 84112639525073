<template>
  <div id="app">
    <Header></Header>
    <section class="section" >
      <router-view />
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
export default {
  name: '',
  components: { Header, Footer, },
  data() {
    return {
      isActive: false,
    }
  },
  //方法集合
  methods: {
    // // 获取页面滚动距离
    // handleScroll() {
    //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   console.log(scrollTop);
    //   if (scrollTop >= 40) {
    //     this.isActive = true;
    //   } else {
    //     this.isActive = false;
    //   }
    // },
  },
  //生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
}


</script>
<style></style>
