<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade carousel-dark" data-bs-ride="carousel"
        >
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/img/hero-bg2.jpg" class="d-block w-100" alt="...">
            <div class="hero-desc carousel-caption">
              <h5>赋能企业数字化转型</h5>
              <p>启源未来科技致力于为企业提供全方位的软件解决方案，助力客户实现业务创新与增长</p>
            </div>
          </div>
          <!-- <div class="carousel-item">
            <img src="../assets/img/hero-bg2.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../assets/img/hero-bg3.jpg" class="d-block w-100" alt="...">
          </div> -->
        </div>
      </div>
    </section><!-- End Hero -->

    <main id="main">
      <!-- 特色服务 -->
      <section id="featured-services" class="featured-services">
        <div class="container">
          <div class="row">
            <!-- 992以上用4个，768到992用2个，以下用1个 -->
            <div class="col-lg-3 col-md-6 col-6  d-flex mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                <h4 class="title"><a href="">企业数字化转型</a></h4>
                <p class="description">提供全面的数字化转型解决方案，助力企业提升运营效率</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class='bx bx-universal-access'></i></div>
                <h4 class="title"><a href="">智能化管理平台</a></h4>
                <p class="description">打造智能化管理平台，实现业务流程的自动化和智能化</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon"><i class='bx bx-spreadsheet'></i></div>
                <h4 class="title"><a href="">数据分析服务</a></h4>
                <p class="description">专业的数据分析服务，助力企业洞察市场趋势和商业机会</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6 d-flex mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class='bx bx-analyse'></i></div>
                <h4 class="title"><a href="">系统运维支持</a></h4>
                <p class="description">极速响应，贴心服务，助力系统高效运维</p>
              </div>
            </div>
          </div>

        </div>

      </section>
      <!-- 关于我们 -->

      <!-- <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3>Find Out More <span>About Us</span></h3>
            <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae
              autem.</p>
          </div>
          <div class="row">
            <div class="col-6 " data-aos="fade-right" data-aos-delay="100">
              <img src="../assets/img/about.jpg" class="d-block w-100" alt="">
            </div>
            <div class="col-6" data-aos="fade-right" data-aos-delay="100">
              <img src="../assets/img/about.jpg" class="d-block w-100" alt="">
            </div>
          </div>
        </div>
      </section> -->
      <!-- ======= Skills Section ======= -->
      <!-- <section id="skills" class="skills">
        <div class="container" data-aos="fade-up">
          <div class="row skills-content">

            <div class="col-lg-6">

              <div class="progress">
                <span class="skill">HTML <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuenow="100"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">CSS <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuenow="90"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">JavaScript <i class="val">75%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">

              <div class="progress">
                <span class="skill">PHP <i class="val">80%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="80"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuemin="0"
                    aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">WordPress/CMS <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuemin="0"
                    aria-valuemax="100">
                  </div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">Photoshop <i class="val">55%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="55"
                    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" aria-valuemin="0"
                    aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section> -->
      <!-- End Skills Section -->
      <!-- ======= Counts Section ======= -->
      <!-- <section id="counts" class="counts">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Happy Clients</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div class="count-box">
                <i class="bi bi-journal-richtext"></i>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Projects</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="bi bi-headset"></i>
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Hours Of Support</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="bi bi-people"></i>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Hard Workers</p>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <!-- ======= Clients Section ======= -->
      <section id="clients" class="clients section-bg">
        <div class="container" data-aos="zoom-in">
          <div class="row">
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-1.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-2.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-3.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-4.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-5.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/clients/client-6.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

      </section>
     

      <!-- ======= Team Section ======= -->
      <!-- <section id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h3>Our Hardworking <span>Team</span></h3>
            <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae
              autem.</p>
          </div>

          <div class="row">

            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-1.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-2.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>Sarah Jhonson</h4>
                  <span>Product Manager</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-3.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>William Anderson</h4>
                  <span>CTO</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-4.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>Amanda Jepson</h4>
                  <span>Accountant</span>
                </div>
              </div>
            </div>


            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-3.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>William Anderson</h4>
                  <span>CTO</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
              <div class="member">
                <div class="member-img">
                  <img src="../assets/img/team/team-4.jpg" class="img-fluid" alt="">
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <h4>Amanda Jepson</h4>
                  <span>Accountant</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section> -->
      
      <!-- End Team Section -->

     
      <!-- ======= Testimonials Section ======= -->
      <section id="testimonials" class="testimonials">
        <div class="container" data-aos="zoom-in">

          <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <img src="../assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    启源未来信息科技，以开启创新源泉为使命，致力于通过前沿软件赋能助力客户塑造卓越未来，秉持专业、协作、创新的价值观，愿景是成为软件赋能领域的领航者，驱动各行业数字化变革与无限发展。
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div><!-- End testimonial item -->

            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div>
      </section>
      
      
      <!-- End Testimonials Section -->

    </main>
  </div>
</template>

<script>
import Purecounter from '@srexi/purecounterjs';
import Swiper from 'swiper/swiper-bundle.min.js'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      oneWidth: '100%',
      intersectionOptions: {
        root: document.querySelector(".skills-content"),
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      }
    }
  },
  created() {

  },
  //生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    new Swiper('.testimonials-slider', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
    const glightbox = this.$GLightbox({
      autoplayVideos: true,
    });
    glightbox.on('open', () => {

    });

    const pure = new Purecounter();


  },
  methods: {
    onWaypoint({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        let progress = this.select('.progress .progress-bar', true);
        progress.forEach((el) => {
          el.style.width = el.getAttribute('aria-valuenow') + '%'
        });
      }
      // if (direction === this.$waypointMap.DIRECTION_TOP) {
      //     console.log('waypoint going top!')
      // }
    },
    select(el, all) {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

  },
}
</script>
