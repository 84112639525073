<template>
    <div>
        <header id="header" class="d-flex align-items-center" :class="{ 'header-top': isActive }">
            <div class="container d-flex align-items-center justify-content-between">
                <div class="logo">
                    山西启源未来
                    <!-- <img src="../assets/img/logo.png" /> -->
                </div>
                <nav id="navbar" class="navbar" :class="{'navbar-mobile':clickNav}">
                    <!-- <ul>
                        <li  v-for="item in navList" :key="item.id" :class="item.children?'dropdown':''"  >
                            <a class="nav-link scrollto" v-if="!item.children" @click="selectNav(item.path,item.path)" :class="isSelect === item.path ? 'active' : ''"
                                href="#"><router-link :to="item.path">{{ item.title }}</router-link></a>
                            <a v-if="item.children" class="nav-link scrollto"  @click="childrenClick()" :class="isSelect === item.path ? 'active' : ''" href="#">{{ item.title }}<i class="bi bi-chevron-down"></i></a>    
                            <ul v-if="item.children" :class="showChiledren ? 'dropdown-active' : ''">
                                <li v-for="ch in item.children" :key="ch.id">
                                    <a  href="#" @click="selectNav(item.path,ch.path)"><router-link style="border: none;" :to="ch.path">{{ ch.title }}</router-link></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle" @click="mobileClick()"></i> -->
                </nav>
            </div>
        </header>
    </div>
</template>

<script>
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import 引入的组件需要注入到对象中才能使用
    components: {},
    props: {},
    data() {
        //这里存放数据
        return {
            isActive: false,
            clickNav:false,
            showChiledren:false,
            navList: [{
                    id: 1,
                    path: '/home',
                    title: '首页',
                },
                {
                    id: 2,
                    path: '/product',
                    title: '产品中心',
                    children:[
                        {
                            id: 21,
                            path: '/product/product-one',
                            title: '产品中心1',
                        },
                        {
                            id: 22,
                            path: '/product/product-two',
                            title: '产品中心2',
                        },
                        {
                            id: 23,
                            path: '/product/product-three',
                            title: '产品中心3',
                        },
                    ],
                },
                {
                    id: 3,
                    path: '/jjfa',
                    title: '解决方案',
                },
                {
                    id: 4,
                    path: '/news',
                    title: '新闻中心',
                },
                {
                    id: 5,
                    path: '/about',
                    title: '关于我们',
                },],
            isSelect: '/home',
        };
    },
    //计算属性 类似于 data 概念
    computed: {},
    //监控 data 中的数据变化
    watch: {},
    //方法集合
    methods: {
        childrenClick(){
           this.showChiledren=!this.showChiledren;
        },
        mobileClick(){
            this.clickNav=!this.clickNav;
        },
        selectNav(dhVal,val) {
            this.isSelect = dhVal;
            this.clickNav=false;
            // this.$router.push({path:val})
        },
        // 获取页面滚动距离
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop >= 40) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        },
        // init() {
        //     this.navList = [
        //         {
        //             id: 1,
        //             path: 'home',
        //             title: '首页',
        //         },
        //         {
        //             id: 2,
        //             path: 'product',
        //             title: '产品中心',
        //             children:[
        //                 {
        //                     id: 21,
        //                     path: 'product/product-one',
        //                     title: '产品中心1',
        //                 },
        //                 {
        //                     id: 22,
        //                     path: 'product/product-two',
        //                     title: '产品中心2',
        //                 },
        //                 {
        //                     id: 23,
        //                     path: 'product/product-three',
        //                     title: '产品中心3',
        //                 },
        //             ],
        //         },
        //         {
        //             id: 3,
        //             path: 'jjfa',
        //             title: '解决方案',
        //         },
        //         {
        //             id: 4,
        //             path: 'news',
        //             title: '新闻中心',
        //         },
        //         {
        //             id: 5,
        //             path: 'about',
        //             title: '关于我们',
        //         },
        //     ];
        // },
    },
    //生命周期 - 创建完成（可以访问当前 this 实例）
    created() {
        // this.init();
    },
    //生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
}
</script>
<style scoped>
.logo{
    color: #2563EB;
    font-family: Pacifico;
    font-size: 24px;
}
</style>