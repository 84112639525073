import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GLightbox from 'glightbox';

import 'glightbox/dist/js/glightbox.min.js';
import "@popperjs/core";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'glightbox/dist/css/glightbox.min.css';
import "bootstrap" 

import 'font-awesome/css/font-awesome.min.css';
import './assets/css/reset.css'
import './assets/css/home.css'
import 'boxicons/dist/boxicons.js'
import 'boxicons/css/boxicons.min.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import VueWaypoint from 'vue-waypoint'

import 'swiper/swiper-bundle.min.css'

AOS.init(
  {
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  }
);

Vue.config.productionTip = false
Vue.prototype.$GLightbox = GLightbox;
Vue.use(VueWaypoint)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
